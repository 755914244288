import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Indicator, NavButton } from "@dexibit/core";
import { Logo } from "../../assets/logo";
import { useAppSelector } from "../../store";
import { useLazyCheckAuthenticationQuery } from "../../store/services/authentication";
import { selectRedirectUri, useGetConfigQuery } from "../../store/services/config";
import { redirectToAuthorise } from "../../utils/oauth";
import { LogoContainer, NavContent, NavLinksList, StyledNav, StyledOverlay, } from "./styles";
export const Navigation = ({ links, activeLink, dialogVisible, }) => {
    const navigate = useNavigate();
    const { data: config } = useGetConfigQuery();
    const redirectUri = useAppSelector(selectRedirectUri);
    const [checkAuth, results] = useLazyCheckAuthenticationQuery();
    const adminLink = links.find((link) => link.title === "Admin");
    const moduleLinks = links.filter((link) => link.title !== "Admin");
    const onNavigationClick = (link) => {
        if (!config)
            return;
        void checkAuth({ verifyTokenUri: config.verifyTokenUri });
        const options = link.iFrameUrl ? { state: { iFrameUrl: link.iFrameUrl } } : undefined;
        navigate(link.to, options);
    };
    useEffect(() => {
        const { isUninitialized, isFetching, isError } = results;
        if (!isUninitialized && !isFetching && isError && redirectUri) {
            redirectToAuthorise(redirectUri);
        }
    }, [results, redirectUri]);
    return (_jsxs(_Fragment, { children: [_jsxs(StyledNav, { children: [_jsxs(NavContent, { children: [_jsx(LogoContainer, { children: _jsx(Logo, {}) }), _jsx(NavLinksList, { children: moduleLinks.map((link) => {
                                    var _a;
                                    return (_jsx("li", { children: _jsx(NavButton, { id: link.title, text: link.title, icon: link.icon, "data-cy": `${link.title.toLowerCase()}-nav-item`, selected: activeLink === null || activeLink === void 0 ? void 0 : activeLink.to.startsWith(link.to), onClick: link.subLinks ? undefined : () => onNavigationClick(link), items: (_a = link.subLinks) === null || _a === void 0 ? void 0 : _a.map((sublink) => {
                                                const isSelected = sublink.to === (activeLink === null || activeLink === void 0 ? void 0 : activeLink.to);
                                                const itemProps = {
                                                    id: sublink.title,
                                                    label: sublink.title,
                                                    onClick: () => onNavigationClick(sublink),
                                                };
                                                if (isSelected) {
                                                    itemProps.startAdornment = [
                                                        _jsx(Indicator, { variant: "info" }, "start"),
                                                    ];
                                                }
                                                return itemProps;
                                            }) }) }, link.title));
                                }) })] }), adminLink && (_jsx(NavButton, { id: adminLink.title, "data-cy": `${adminLink.title.toLowerCase()}-nav-item`, text: adminLink.title, icon: adminLink.icon, selected: adminLink.to === (activeLink === null || activeLink === void 0 ? void 0 : activeLink.to), onClick: () => onNavigationClick(adminLink) }))] }), dialogVisible && _jsx(StyledOverlay, {})] }));
};
