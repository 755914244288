import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "../store";
import { useCheckAuthenticationQuery } from "../store/services/authentication";
import { selectRedirectUri, useGetConfigQuery } from "../store/services/config";
import { useGetUserInfoQuery } from "../store/services/userInfo";
import { redirectToAuthorise } from "../utils/oauth";
import tokenManager, { TOKEN_KEY } from "../utils/tokenManager";
// Hook which handles app initialisation.
// First fetches config, then checks if user is authenticated
// If user is authenticated then fetch userinfo, otherwise redirect to login
export const useInit = () => {
    // Fetch token from cookie if exists, otherwise fetch from local storage
    const storedToken = tokenManager.getToken();
    const cookies = document.cookie.split(";");
    const tokenCookie = cookies.find((cookie) => cookie.trim().startsWith(`${TOKEN_KEY}=`));
    const token = tokenCookie ? tokenCookie.split("=")[1] : storedToken;
    useEffect(() => {
        if (!token)
            return;
        tokenManager.setToken(token);
        // Remove token cookie once it's set in localStorage
        // When deleting a cookie the only value that matters is ensuring that 'expires' is in the past.
        // Most browsers will also prevent the cookie from being deleted unless the correct path is specified.
        // The actual value of the cookie does not need to be specified on deletion.
        document.cookie = `${TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }, [token]);
    // Fetch config
    const { data: config, isError: isConfigError, isUninitialized: isConfigUninitialized, } = useGetConfigQuery();
    const oidcClientId = config === null || config === void 0 ? void 0 : config.oidcClientId;
    const redirectUri = useAppSelector(selectRedirectUri);
    // Check token valid
    const { data: authenticated, isError: isAuthError, isLoading: isAuthLoading, } = useCheckAuthenticationQuery(oidcClientId
        ? {
            verifyTokenUri: config.verifyTokenUri,
        }
        : // https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#skiptoken
            skipToken);
    // Fetch user info if authenticated and no auth error
    const { data: userInfo, isError: isUserInfoError, isLoading: isUserInfoLoading, } = useGetUserInfoQuery(authenticated && !isAuthError && config
        ? { userInfoUri: config.userInfoUri }
        : // https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#skiptoken
            skipToken);
    // If not authenticated then redirect to login
    useEffect(() => {
        if (!isAuthLoading && !authenticated && redirectUri) {
            redirectToAuthorise(redirectUri);
        }
    }, [isAuthLoading, authenticated, redirectUri]);
    return {
        config,
        userInfo,
        authenticated,
        isInitError: isConfigError || isUserInfoError,
        isInitLoading: isAuthLoading || isUserInfoLoading,
        isUninitialized: isConfigUninitialized,
    };
};
