import { useLocation } from "react-router-dom";
export const getFromParams = (key) => {
    const searchParams = new URLSearchParams(window.location.search);
    const param = searchParams.get(key);
    return param;
};
export const getIframeProps = (links) => {
    var _a;
    const pathname = useLocation().pathname;
    const currentLink = links.find((link) => pathname.startsWith(link.to));
    const currentSubLink = (_a = currentLink === null || currentLink === void 0 ? void 0 : currentLink.subLinks) === null || _a === void 0 ? void 0 : _a.find((subLink) => pathname.startsWith(subLink.to));
    const link = currentSubLink || currentLink;
    return link
        ? {
            id: link.title,
            url: link.iFrameUrl,
            key: pathname,
        }
        : null;
};
