import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useLocation } from "react-router-dom";
import { StyledIframe } from "../styles";
import { getIframeProps } from "../utils/url";
const IFramePage = ({ links }) => {
    const location = useLocation();
    const state = location.state;
    const iframeProps = getIframeProps(links);
    if (!iframeProps)
        return null;
    let fullUrl = (state === null || state === void 0 ? void 0 : state.iFrameUrl) ? state.iFrameUrl : iframeProps.url;
    // Handles special routing case for edit profile
    if (iframeProps.id === "Admin") {
        fullUrl = `${iframeProps.url}#${state === null || state === void 0 ? void 0 : state.path}${state === null || state === void 0 ? void 0 : state.id}`;
    }
    return (_jsx(StyledIframe, { title: iframeProps.id, id: iframeProps.id, src: fullUrl, allow: "clipboard-write" }, iframeProps.key));
};
export default IFramePage;
